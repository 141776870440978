import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import SmallBanner from "@components/common/SmallBanner";
import "@styles/Privacidad.css";

export default function Index() {
    return (
        <>
            <Layout>
                <Seo title="Condiciones de compra" />
                <SmallBanner title="Condiciones de compra" filename="bgs/bgPrivacy" />
                <div className="container lg:px-0 py-20">
                    <div className="mb-10 privacidad">
                        <Title className="text-left">Condiciones generales de compra</Title>
                        <Text className="text-justify">
                            A continuación, te facilitamos las condiciones generales de compra de
                            nuestros artículos o cursos, para que conozcas cómo podrás adquirirlos y
                            cuáles son tus derechos como consumidor.
                            <br />
                            <br />
                            Estas condiciones resultarán de aplicación a las compras que realices a
                            través de las páginas web localizables bajo el dominio "iopos.es" ("El
                            Sitio Web") titularidad de "iOPOS, SL" ("iOpos"), siendo necesario que
                            las aceptes cuando realices cada pedido, marcando la casilla que te
                            mostramos al efecto antes de abonar tu compra.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Nuestros productos</Title>
                        <Text className="text-justify">
                            <ul className="ml-5 list-decimal">
                                <li>
                                    <strong>Características:</strong> Todos los artículos o cursos
                                    que puedes comprar a través del Sitio Web se encuentran
                                    identificados mediante la imagen de su portada, un nombre y una
                                    descripción resumida de su contenido más relevante.
                                </li>
                                <li>
                                    <strong>Disponibilidad:</strong> El número de unidades de venta
                                    por producto o plazas disponibles, en caso que sea un curso, se
                                    mantiene actualizdo en el Sitio Web, según disponibilidad. Por
                                    ello, cuando no dispongamos de algún artículo de manera puntual,
                                    te lo indicaremos junto a dicho producto. Ídem en el caso de los
                                    cursos. Si, por el contrario, nos quedásemos sin existencias del
                                    mismo, lo retiraríamos del Sitio Web o bloqueríamos la compra.
                                    <br />
                                    <br />
                                    En todo caso, si casual y excepcionalmente, el Sitio Web
                                    anunciase la disponibilidad de algún producto del que no hubiera
                                    stock por causas inevitables o de fuerza mayor -errores humanos
                                    o incidencias en el sistema informático- y, en tales
                                    circunstancias, el sistema te permitiese comprarlo (pagarlo), te
                                    pedimos encarecidamente disculpas. En ese caso, tan pronto como
                                    tengamos constancia de tu pedido, te informaremos de lo sucedido
                                    y procederemos a reembolsarte el importe que hayas abonado en un
                                    plazo máximo de 72 horas.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Precios. Gastos de envío</Title>
                        <Text className="text-justify">
                            <ul className="ml-5 list-disc">
                                <li>
                                    El precio de los productos o cursos ofrecidos a través del Sitio
                                    Web incluyen el I.V.A correspondiente.
                                </li>
                                <li>
                                    En cuanto a los gastos de envío que llevará aparejada tu compra,
                                    podrás identificarlos fácilmente acudiendo al "carro de la
                                    compra" que localizarás en el margen superior derecho del Sitio
                                    Web (icono carrito). Allí, constarán debidamente identificados,
                                    de forma separada al precio de tu producto (impuestos
                                    incluidos).
                                </li>
                                <li>
                                    Los gastos de envío se determinarán en función del número de
                                    artículos que selecciones para su compra. En todo caso, como te
                                    indicamos en el apartado ‘PROCESO DE COMPRA', existirá un límite
                                    máximo por gastos de envío que conocerás antes de proceder al
                                    abono de tu compra.
                                </li>
                                <li>
                                    Puede que hayan portes gratis en promociones concretas, en cuyo
                                    caso te lo especificaremos.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Territorio de entrega</Title>
                        <Text className="text-justify">
                            Los productos ofrecidos a través del Sitio Web no se venden fuera de
                            España. Ello implica que nuestro pedidos podrán ser entregados en
                            cualquier lugar de la península, Islas Canarias, Islas Baleares, Ceuta y
                            Melilla.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Formas de pago</Title>
                        <Text className="text-justify">
                            <ul className="ml-5 list-disc">
                                <li>
                                    Las compras realizadas a través del Sitio web podrán abonarse
                                    mediante Paypal o bien a través de tarjeta de débito o crédito
                                    (siempre a través) de la pasarela de Paypal.
                                </li>
                                <li>
                                    En todo caso, ten en cuenta que iOpos no admite el pago a través
                                    de domiciliación bancaria, contra reembolso ni métodos
                                    similares.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Proceso de compra</Title>
                        <Text className="text-justify">
                            Hacer un pedido en el Sitio Web resulta muy sencillo y te lo explicamos
                            a continuación.
                            <br />
                            <br />
                            En todo caso, si tienes alguna cuestión al respecto, por favor, no dudes
                            en contactar con nosotros a través de alguno de los medios que ponemos a
                            su disposición en el apartado "CONTACTO".
                            <ul className="ml-5 list-decimal mt-5">
                                <li>
                                    <strong>Compara y elige</strong>
                                    <br />
                                    Dirígete a cualquiera de las secciones que localizarás en el
                                    Sitio Web, de acuerdo con tus preferencias y escoge la sección
                                    que te interese.
                                    <br />
                                    Hecho esto, te mostraremos los artículos disponibles para esa
                                    sección. Podrás consultar, entonces, el resumen de contenidos
                                    del producto o curso que te interese.
                                </li>
                                <li>
                                    <strong>Añádelo al carro de compra</strong>
                                    <br />
                                    Cuando hayas localizado el/los artículo/s de tu interés, indica
                                    si deseas uno o más (haciendo clic en el botón de añadir al
                                    carro) y pulsa el botón 'AÑADIR A LA CESTA' para que pueda
                                    añadirse a tu ‘CESTA DE LA COMPRA'. (En todo caso, ten en cuenta
                                    que este gesto aún no implica que hayas formalizado tu pedido,
                                    para lo que será necesario que aceptes las presentes condiciones
                                    y, posteriormente, efectúes el abono de dicho pedido cuando te
                                    lo indiquemos). Una vez seleccionados todos los artículos que
                                    deseas comprar, dirígete a la 'CESTA DE LA COMPRA' y clica en
                                    'REALIZAR PAGO'. Tras ello, te mostraremos un resumen de tu
                                    pedido en el que te indicaremos el total del pedido y los gastos
                                    de envío (con su I.V.A. incluido).
                                </li>
                                <li>
                                    <strong>Introduce tus datos personales</strong>
                                    <br />
                                    Seguidamente, te encontrarás cuatro pequeños campos en los que
                                    has de rellenar tus datos personales: tu nombre, el teléfono de
                                    contacto, tu correo electrónico y la dirección de envío (en caso
                                    que sea necesaria). Una vez introducidos puedes marcar "el
                                    checbox" y hacer clic en el botón "REALIZAR PAGO".
                                </li>
                                <li>
                                    <strong>Realizar Pago</strong>
                                    <br />
                                    Para realizar el pago tendrás que hacer clic en el botón "PAGAR
                                    CON PAYPAL" y luego introducir tus credenciales. Si todo ha ido
                                    bien recibirás un mensaje en pantalla indicando que todo ha sido
                                    correcto y además un correo electrónico con el resum de la
                                    compra. Si no recibes el correo en unos minutos puedes mirar en
                                    "Correo No Deseado".
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">
                            Devoluciones. Cancelación del Pedido e Incidencias
                        </Title>
                        <Text className="text-justify">
                            <ul className="ml-5 list-disc mb-5">
                                <li>
                                    No se admiten devoluciones de artículos adquiridos en el Sitio
                                    Web. Con todo, aceptamos que canceles un pedido si aún no consta
                                    como enviado. Para comprobarlo debes comunicárnoslo lo más
                                    pronto posible a través de nuestro correo de contacto
                                    "compras@iopos.academy".
                                </li>
                                <li>
                                    En caso de que hayamos confundido tu pedido o el artículo que
                                    hayas recibido estuviese en mal estado, indícanoslo a la mayor
                                    brevedad (máximo 4 días), por favor, y procederemos a enviártelo
                                    correctamente o sustituirlo por otro idéntico en perfecto
                                    estado, conforme a los plazos indicados en el apartado previo.
                                </li>
                                <li>
                                    Si se trata de un producto online (no físico), podrá solicitarse
                                    una devolución solo en el caso en que no se hayan pasado más de
                                    14 días desde su compra o si no ha sido utilizado más de 15
                                    veces (15 test*).
                                </li>
                            </ul>
                            <p className="text-right italic text-sm">
                                * Solo aplicable a módulos de aplicaciones
                            </p>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Atención al Cliente</Title>
                        <Text className="text-justify">
                            Si tienes cualquier consulta sobre su pedido o necesita ayuda para
                            completar el proceso de compra, estamos a su disposición.
                            <br />
                            <br />
                            Para garantizar que puedas ser atendido ágil y convenientemente,
                            contáctanos, por favor, escribiéndonos a{" "}
                            <a href="malito:info@iopos.academy" className="link">
                                info@iopos.academy
                            </a>{" "}
                            o bien a través del WhatsApp{" "}
                            <a href="https://wa.me/34644507478" className="link">
                                644 507 478
                            </a>
                            ; como prefieras.
                            <br />
                            <br />
                            Aunque desde el Departamento de Atención al Cliente de iOpos estamos a
                            tu disposición para ayudarte, atendiendo cualquier consulta o
                            disconformidad que quieras transmitirnos con respecto a tu compra, te
                            informamos de que la Comisión Europea ha habilitado una plataforma -de
                            resolución alternativa de conflictos- a la que puedes acceder en el
                            siguiente enlace si estimas que, tras haber contactado con nosotros, tu
                            problema no se ha tramitado convenientemente:{" "}
                            <a
                                target="_blank"
                                href="https://europa.eu/youreurope/business/dealing-with-customers/solving-disputes/online-dispute-resolution/index_es.htm"
                                rel="noopener noreferrer"
                                className="link"
                            >
                                Plataforma UE resolución alternativa
                            </a>
                            .
                        </Text>
                    </div>
                </div>
            </Layout>
        </>
    );
}
